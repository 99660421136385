/* src/app/EnlaceFibra.css */

/* Banner Container */
.banner-container {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    overflow: hidden;
  }
  
  .banner-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .banner-content {
    position: relative;
    display: flex;
    align-items: center;
    width: 80%;
    z-index: 2;
  }
  
  .text-with-line {
    color: white;
    font-size: 1.5rem;
    margin: 2rem;
    padding: 0 2rem;
    padding-left: 2rem;
    border-left: 5px solid #ff5100;
    text-align: justify;
    font-weight: 300;
  }
  
  /* Descripción */
  .description {
    padding: 2rem;
    text-align: left;
    color: black;
  }
  
  .description h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: black;
  }
  
  .description p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: black;
  }
  
  .service-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .service-list li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: black;
  }
  
  .service-list li .fa-check-circle {
    color: #4caf50;
    margin-right: 0.5rem;
  }
  
  /* Responsividad */
  @media (max-width: 768px) {
    .banner-container {
      height: 200px;
    }
  
    .banner-content {
      width: 90%;
    }
  
    .text-with-line {
      font-size: 1.25rem;
      padding: 0 1.5rem;
      padding-left: 1.5rem;
      border-left-width: 3px;
      margin: 1.5rem;
      text-align: center;
    }
  
    .description {
      padding: 2rem;
    }
  
    .description h2 {
      font-size: 1.25rem;
    }
  
    .description p {
      font-size: 0.875rem;
    }
  
    .service-list {
      padding: 1rem;
    }
  
    .service-list li {
      font-size: 0.875rem;
      margin-bottom: 0.75rem;
    }
  }
  
  @media (max-width: 480px) {
    .banner-container {
      height: 150px;
    }
  
    .text-with-line {
      font-size: 1rem;
      padding: 0 1rem;
      padding-left: 1rem;
      border-left-width: 2px;
      margin: 1rem;
      text-align: center;
    }
  
    .description {
      padding: 2rem;
    }
  
    .description h2 {
      font-size: 1rem;
    }
  
    .description p {
      font-size: 13px;
      text-align: justify;
    }
  
    .service-list {
      padding: 0.5rem;
    }
  
    .service-list li {
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }
  }
  
  /* Margenes laterales en pantallas grandes */
  @media (min-width: 1200px) {
    .description {
      margin-left: 10%;
      margin-right: 10%;
    }
  }
  