.floating-whatsapp {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 60px; /* Tamaño del botón */
    height: 60px; /* Tamaño del botón */
    background-color: #25D366; /* Color verde de WhatsApp */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Asegúrate de que esté encima de otros elementos */
    text-decoration: none;
    animation: heartbeat 1.5s infinite; /* Aplica la animación */
}

.floating-whatsapp i {
    font-size: 30px; /* Aumenta el tamaño del ícono */
    margin: 0;
}

/* Define la animación de latido */
@keyframes heartbeat {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}
