@tailwind base;
@tailwind components;
@tailwind utilities;


/* Font definitions */
.montserrat-regular {
  font-family: "Montserrat", sans-serif;
  font-weight: 400; /* Normal weight */
  font-style: normal;
}

.montserrat-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 700; /* Bold weight */
  font-style: normal;
}

/* Example for italic styles */
.montserrat-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: italic;
}

/* Global styles */
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Apply Montserrat Regular to paragraphs */
p {
  @apply montserrat-regular;
  line-height: 1.5; /* Adjust as needed */
}

/* Apply Montserrat Bold to h1 headings */
h1 {
  @apply montserrat-bold;
  line-height: 1.2; /* Adjust as needed */
}

/* Apply Montserrat Bold to h2 headings */
h2 {
  @apply montserrat-bold;
  line-height: 1.3; /* Adjust as needed */
}
h3 {
  @apply montserrat-bold;
  line-height: 1.3; /* Adjust as needed */
}
li{
  @apply montserrat-regular;
}
span {
  @apply montserrat-regular; /* Aplica un peso de fuente semibold */
}


a{
  @apply montserrat-regular;
}
