/* src/components/Footer.css */

.footer {
  background-color: #737373;
  color: #ffffff;
  padding: 48px 16px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  max-width: 150px;
}

.info {
  text-align: left;
}

.info-item {
  display: flex;
  font-size: 15px;
  align-items: center;
  margin-bottom: 3px;
}

.info-icon {
  margin-right: 8px;
}

.email-link {
  color: #ffffff;
  text-decoration: none;
}

.email-link:hover {
  text-decoration: none; /* Remove underline on hover */
}

.quick-links {
  text-align: left;
}

.quick-links ul {
  list-style: none;
  padding: 0;
}

.footer-link {
  color: #ffffff;
  text-decoration: none;
  display: block;
  margin-bottom: 8px;
}

.footer-link:hover {
  text-decoration: none; /* Remove underline on hover */
}

.social-media {
  text-align: left;
}

.social-icon {
  color: #ffffff;
  font-size: 1rem;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #007bff;
}

.derechos{
  text-align: center;
}
