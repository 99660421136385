.navbar-mini {
  background-color: #ff5100;
  color: white;
  padding: 0.3rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.contact-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin-left: 9em; /* Mueve la información de contacto 4em a la derecha */
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 12px;
}

.social-icons {
  margin-right: 9em;
  display: flex;
  gap: 1rem;
  margin-top: 0rem;
}

.icon {
  color: white;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #e0a400;
}

/* Ocultar el NavbarMini en dispositivos móviles */
@media (max-width: 768px) {
  .navbar-mini {
      display: none;
  }
}
